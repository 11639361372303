import styled from "styled-components";

export const Button = styled.div`
  width: 20px;
  align-items: flex-end;
  @media ${({ theme }) => theme.large} {
    width: 160px;
    align-items: center;
  }
  height: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
`;
