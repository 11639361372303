import React from 'react'
import { Button } from './arrows.styled'

export const PrevArrow = ({ onClick = undefined, className = undefined }) => {
    return (
        <Button onClick={onClick} className={className} >
            <svg width="8" height="16" viewBox="0 0 8 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7 1.20154L1 7.79855L7 14.3956" stroke="#7A7A7A" strokeWidth="2" />
            </svg>
        </Button>

    )
}
export const NextArrow = ({ onClick = undefined, className = undefined }) => {
    return (
        <Button onClick={onClick} className={className} >
            <svg width="8" height="16" viewBox="0 0 8 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1.20154L7 7.79855L1 14.3956" stroke="#7A7A7A" strokeWidth="2" />
            </svg>
        </Button>
    )
}

