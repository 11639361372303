import { useQuery } from "@apollo/client";
import { useStaticQuery, graphql } from "gatsby";
import { CONTENTFUL_TESTIMONIALS } from "../queries/contentful/testimonials";

const useTestimonials = () => {
  if (process.env.GATSBY_CONTENTFUL_HOST) {
    const { data } = useQuery(CONTENTFUL_TESTIMONIALS);
    return data?.testimonialSliderCollection?.items[0]?.testimonialsCollection
      ?.items;
  }
  const ssrData = useStaticQuery(graphql`
    query gatsbyTestimonials {
      allContentfulTestimonialSlider {
        nodes {
          testimonials {
            name
            text {
              text
            }
          }
        }
      }
    }
  `);
  return ssrData?.allContentfulTestimonialSlider?.nodes[0]?.testimonials?.map(
    (item) => {
      return { name: item.name, text: item.text.text };
    }
  );
};

export default useTestimonials;
