import { gql } from "@apollo/client";

export const CONTENTFUL_TESTIMONIALS = gql`
  query getContentfulTestimonials {
    testimonialSliderCollection(preview: true) {
      items {
        testimonialsCollection {
          items {
            name
            text
          }
        }
      }
    }
  }
`;
