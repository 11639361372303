import { useQuery } from "@apollo/client";
import { GET_PAGE_BY_SLUG_QUERY } from "../queries/contentful/pageBySlug";

const usePage = (id = "", data) => {
  if (process.env.GATSBY_CONTENTFUL_HOST) {
    const { data } = useQuery(GET_PAGE_BY_SLUG_QUERY, {
      variables: { id },
    });
    return data?.page;
  }
  
  return data?.contentfulSeite;
};
export default usePage;
