import React, { useMemo } from 'react'
import Image from "gatsby-image";
import * as Masonry from './styled'
import useProducts from '../../hooks/useProducts'

const Products = () => {
    const data = useProducts()
    function getRandomNumberBetween(min, max) {
        return Math.random() * (max - min) + min;
    }
    const sizes = useMemo(() => [...new Array(200)].map((image) => {
        return {
            medium: getRandomNumberBetween(35, 55),
            large: getRandomNumberBetween(20, 35)
        }
    }), [data])

    return (
        <Masonry.Wrapper>
            {data?.map((item, i) =>
                <Masonry.Item key={i} to={`/${item.slug}`} medium={sizes[i].medium} large={sizes[i].large} >
                    <Masonry.Background>
                        <Image
                            fluid={item.images[0].fluid}
                        />
                    </Masonry.Background>
                    <Masonry.Info>
                        <Masonry.Name>{item.name}</Masonry.Name>
                        <Masonry.Type>{item.type}</Masonry.Type>
                    </Masonry.Info>
                </Masonry.Item>
            )}
        </Masonry.Wrapper>
    )
}

export default Products
