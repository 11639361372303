import { gql } from "@apollo/client";

export const GET_PAGE_BY_SLUG_QUERY = gql`
  query getPageBySlug($id: String!) {
    page(id: $id, preview: true) {
      slug
      title
      sys {
        id
      }
      content {
        json
        links {
          entries {
            block {
              __typename
              sys {
                id
              }
            }
          }
          assets {
            block {
              url
              sys {
                id
              }
            }
          }
        }
      }
    }
  }
`;
