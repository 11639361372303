import { useQuery } from "@apollo/client";
import { useStaticQuery, graphql } from "gatsby";
import { CONTENTFUL_PRODUCTS } from "../queries/contentful/products";

import { contentfulProductsMapper } from "../mappers/contentful/productsMapper";

const useProducts = () => {
  if (process.env.GATSBY_CONTENTFUL_HOST) {
    const { data } = useQuery(CONTENTFUL_PRODUCTS);
    return contentfulProductsMapper(data);
  }
  const ssrData = useStaticQuery(graphql`
    query gatsbyProducts {
      allContentfulProdukt {
        nodes {
          name
          type
          slug
          images {
            fluid {
              src
              srcSetWebp
              srcSet
              base64
            }
          }
        }
      }
    }
  `);
  return ssrData?.allContentfulProdukt?.nodes;
};
export default useProducts;
