import React, { useState, Fragment } from 'react'
import useTestimonials from '../../hooks/useTestimonials'
import Slider from "react-slick";
import * as Testimonial from './styled'
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css";

const Testimonials = () => {
    const data = useTestimonials()    
    const [currentSlide, setCurrentSlide] = useState(0)
    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <Testimonial.Next />,
        prevArrow: <Testimonial.Prev />,
        beforeChange: (i, n) => setCurrentSlide(n),
    };
    return (
        <Fragment>
            {Array.isArray(data) &&
                <Testimonial.Wrapper>
                    <Testimonial.Container>
                        <Testimonial.Counter>{currentSlide + 1} / {data.length}</Testimonial.Counter>
                        <Slider {...settings}>
                            {data.map((item, i) =>
                                <Testimonial.Slide key={i}>
                                    <Testimonial.Name>{item.name}</Testimonial.Name>
                                    <Testimonial.Text>{item.text}</Testimonial.Text>
                                </Testimonial.Slide>
                            )}
                        </Slider>
                    </Testimonial.Container>
                </Testimonial.Wrapper>
            }
        </Fragment>
    )
}

export default Testimonials
