import React from "react"
import { graphql } from "gatsby"
import Wrapper from '../components/wrapper'
import Products from '../components/products'
import Testimonials from '../components/testimonials'
import usePage from '../hooks/usePage'
import { BLOCKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Contact from '../components/contact'
import Image from 'gatsby-image'

const IndexPage = ({ data, pageContext: { contentful_id, id, slug } }) => {

  const page = usePage(contentful_id, data)
  const componentMap = {
    "Produkts": Products,
    "ContentfulProdukte": Products,
    "TestimonialSlider": Testimonials,
    "ContentfulTestimonialSlider": Testimonials
  }
  const getElementFromMap = (element) => {
    const elementId = element?.data?.target?.sys?.id
    const link = process.env.GATSBY_CONTENTFUL_HOST ?
      page?.content?.links?.entries?.block.find((item) => item.sys.id === elementId) :
      page?.content?.references?.find((item) => item.contentful_id === elementId);

    if (!componentMap[link?.__typename]) {
      return null;
    }
    const Component = componentMap[link?.__typename]
    return <Component {...element} />
  }

  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
      [BLOCKS.EMBEDDED_ENTRY]: node => getElementFromMap(node),
      [BLOCKS.EMBEDDED_ASSET]: node => {
        const image = process.env.GATSBY_CONTENTFUL_HOST ?
          {
            fluid: {
              src: page?.content?.links?.assets?.block.find((item) => item.sys.id === node.data.target.sys.id)?.url
            }
          } : page?.content?.references.find((reference) => reference.contentful_id === node.data.target.sys.id)


        return (
          <Image style={{
            minHeight: "500px",
            width: "100%",
            minWidth: "50%",
            height: "auto"
          }}
            imgStyle={{ objectFit: "contain", width: "innitial" }}
            fluid={image.fluid} />
        )
      },
    },
    renderText: text =>
      text.split("\n").flatMap((text, i) => [i > 0 && <br />, text])
  };
  const json = page?.content?.json || page?.content?.raw && JSON.parse(page.content.raw)

  return (
    <Wrapper title={page?.title}>
      {json && documentToReactComponents(json, options)}
      {slug !== 'impressum' && slug !== 'datenschutzerklaerung' && <Contact />}
    </Wrapper>
  )
}
export const query = graphql`
  query gatsbyPage($id: String!) {
    contentfulSeite(id: { eq: $id }) {
      title
      content {
        raw
        references {
          ... on ContentfulTestimonialSlider {
            id
            contentful_id
            sys {
              type
              revision
            }
            __typename
          }
          ... on ContentfulProdukte {
            id
            contentful_id
            sys {
              type
              revision
            }
            __typename
          }
          ... on ContentfulAsset {
            id
            contentful_id
            fluid{
              src
              base64
              srcWebp
              srcSetWebp
            }
          }
        }
      }
    }
  }
`;
export default IndexPage
