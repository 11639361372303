import styled from "styled-components";
import { Link } from "gatsby";

type MasonryType = {
  medium: number;
  large: number;
};
export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -10px;
  padding: 40px 10px 60px 10px;
  @media ${({ theme }) => theme.medium} {
    padding: 60px 0 80px 0;
  }
`;

export const Item = styled(Link)<MasonryType>`
  height: 310px;
  margin: 10px 0;
  flex: 1 0 auto;
  width: 100%;
  @media ${({ theme }) => theme.medium} {
    margin: 10px 10px;
    width: ${({ medium }) => `${medium}%`};
  }
  @media ${({ theme }) => theme.large} {
    width: ${({ large }) => `${large}%`};
  }
  position: relative;
  cursor: pointer;
`;

export const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  > div {
    height: 100%;
    width: 100%;
  }
`;

export const Info = styled.div`
  position: absolute;
  bottom: 20px;
  left: 20px;
  z-index: 0;
  background: ${({ theme }) => theme.orange};
  padding: 10px;
  min-width: 120px;
`;

export const Name = styled.div`
  font-size: 1.5rem;
  font-family: "Kalam", serif;
  color: ${({ theme }) => theme.white};
`;

export const Type = styled.div`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.white};
`;
