import styled from "styled-components";
import { NextArrow, PrevArrow } from "./arrows";

export const Wrapper = styled.div`
  padding: 40px 0;
`;

export const Container = styled.div`
  position: relative;
  border-radius: 20px;
  background: ${({ theme }) => theme.white};
  padding: 20px 20px;
  .slick-prev {
    bottom: -20px;
    left: 0;
    &:before {
      content: none;
    }
  }
  .slick-next {
    bottom: -20px;
    right: 0;
    &:before {
      content: none;
    }
  }
  .slick-slider {
    height: 100%;
    display: flex;
    align-items: center;
    padding-bottom: 60px;
  }
  @media ${({ theme }) => theme.large} {
    height: 220px;
    padding: 20px 160px;

    .slick-prev {
      bottom: initial;
      left: -160px;
    }
    .slick-next {
      bottom: initial;
      right: -160px;
    }
    .slick-slider {
      padding-bottom: 0;
    }
  }
`;

export const Slide = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  &:focus {
    outline: none;
  }
`;

export const Name = styled.div`
  color: ${({ theme }) => theme.gray2};
  font-family: "Kalam", serif;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1.5rem;
  padding-bottom: 1rem;
`;
export const Counter = styled(Name)`
  background: ${({ theme }) => theme.white};
  padding-bottom: 0;
  position: absolute;
  right: 145px;
  bottom: 14px;
  z-index: 10;
  @media ${({ theme }) => theme.large} {
    bottom: initial;
    top: 80px;
    right: 160px;
  }
`;

export const Text = styled.div`
  color: ${({ theme }) => theme.gray2};
`;

export const Next = styled(NextArrow)``;
export const Prev = styled(PrevArrow)``;
