import { gql } from "@apollo/client";
import { PRODUCT_FRAGMENT } from "../../fragments/contentful/productFragment";

export const CONTENTFUL_PRODUCTS = gql`
  query getContentfulProducts {
    productCollection(preview: true) {
      items {
        ...product
      }
    }
  }
  ${PRODUCT_FRAGMENT}
`;
